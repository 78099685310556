<div class="loginPopupMainDiv" mat-dialog-content>
    <div class="loginContent">
        <div class="loginTitle" *ngIf="!isForgetPasswordEnabled">
            <!-- <img src=" \assets\images\svg\login\AIRADx.png" alt="AIRA Matrix" aria-label="AIRA Matrix" class="center" />
            <span class="loginTitleText">AIRAQc</span> -->
            <img [src]='"../../../" +appConfig.appBaseName+ "assets/images/common/AIRAQC.png"' alt="AIRA Matrix"
                aria-label="AIRA Matrix" class="center" [class.initloginPopupImg]="!this.data.isAccessTokenExpired" />
        </div>

        <div class="loginCreds" [ngClass]="(isChangePassword) ? 'changePasswordInputs' : ''">
            <ng-container *ngIf="!isForgetPasswordEnabled && !isChangePassword && !isResetPassword">
                <mat-form-field class="loginCredentialFields loginEmailId zoomLevelMatFormfield" appearance="outline"
                    [class.readOnlyInput]="data.isAccessTokenExpired">
                    <input type="email" matInput [(ngModel)]="userEmailId" (keyup.enter)="validateLogin()"
                        placeholder="Email Id" [readonly]="data.isAccessTokenExpired">
                </mat-form-field>

                <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                    <input [type]="hide ? 'password' : 'text'" matInput [(ngModel)]="userPassword"
                        (keyup.enter)="validateLogin()" placeholder="Password">
                    <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>

                <div class="invalidCreds" *ngIf="invalidUserCredentials">Please enter valid credentials</div>
            </ng-container>

            <!-- ForgetPassword-->
            <ng-container *ngIf="isForgetPasswordEnabled">
                <div class="forgotPasswordHeader">
                    <span>Account Recovery</span>
                    <mat-icon style="margin-left: auto; font-weight: 600;" (click)="closeForgetPassword()"
                        class="cursorPointer customMatIcon">close</mat-icon>
                </div>
                <div class="passwordRecText" *ngIf="!isMailsend">
                    Enter your Email ID to recieve a Verification Code
                </div>
                <div class="passwordRecText" *ngIf="isMailsend && !isCodeVerified">
                    Enter Verification Code
                </div>
                <div class="passwordRecText" *ngIf="isCodeVerified">
                    Enter New Password
                </div>

                <ng-container *ngIf="!isMailsend">
                    <form [formGroup]="userEmails">
                        <mat-form-field class="loginCredentialFields loginEmailId zoomLevelMatFormfield" appearance="outline">
                            <input type="email" matInput [(ngModel)]="forgetPasswordObj.emailId" placeholder="Email Id"
                                [formControl]="emailControl" formControlName="primaryEmail"
                                [disabled]="data.isAccessTokenExpired" (input)="checkEmail()">
                        </mat-form-field>
                    </form>
                </ng-container>

                <ng-container *ngIf="isMailsend && !isCodeVerified">
                    <mat-form-field class="loginCredentialFields loginEmailId zoomLevelMatFormfield" appearance="outline">
                        <input type="email" matInput [(ngModel)]="forgetPasswordObj.verificationCode"
                            placeholder="Enter verification code" [disabled]="data.isAccessTokenExpired">
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="isCodeVerified">
                    <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                        <input [type]="hideNewPassword ? 'password' : 'text'" matInput
                            [(ngModel)]="forgetPasswordObj.newPassword" placeholder="New password">
                        <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword"
                            [attr.aria-label]="'Hide Newpassword'" [attr.aria-pressed]="hideNewPassword">
                            {{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>

                    <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                        <input [type]="hideConfirmPassword ? 'password' : 'text'" matInput
                            [(ngModel)]="forgetPasswordObj.confirmedPassword" placeholder="Confirm password">
                        <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                            [attr.aria-label]="'Hide Confirmedpassword'" [attr.aria-pressed]="hideConfirmPassword">
                            {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </ng-container>
            </ng-container>

            <!--Change Password-->
            <ng-container *ngIf="isChangePassword">
                <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                    <input [type]="hideOldPassword ? 'password' : 'text'" matInput maxlength="16" minlength="8"
                        [(ngModel)]="forgetPasswordObj.oldPassword" placeholder="Old password">
                    <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideOldPassword">
                        {{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                    <input [type]="hideNewPassword ? 'password' : 'text'" matInput maxlength="16" minlength="8"
                        (input)="validatePasswordBlur()" (blur)="validatePasswordBlur()"
                        [(ngModel)]="forgetPasswordObj.newPassword" placeholder="New password">
                    <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideNewPassword">
                        {{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>

                <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline"
                    [ngStyle]="{'padding-bottom':(invalidNewUserPassword && forgetPasswordObj.newPassword?.length || (forgetPasswordObj.newPassword != forgetPasswordObj.confirmedPassword))? '1vh':'3vh'}">
                    <input [type]="hideConfirmPassword ? 'password' : 'text'" matInput maxlength="16" minlength="8"
                        [(ngModel)]="forgetPasswordObj.confirmedPassword" placeholder="Confirm password">
                    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                        {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <ng-container
                    *ngIf="(invalidNewUserPassword && forgetPasswordObj.newPassword?.length || (forgetPasswordObj.newPassword != forgetPasswordObj.confirmedPassword))">
                    <div class="changePasswordCheckMsg">
                        <div>Make sure your password:</div>
                        <ul>
                            <li>Contain minimum 8 & maximum 16 characters</li>
                            <li>Includes atleast one numeric value, one special characters, one uppercase & lowercase
                                letter</li>
                        </ul>
                    </div>
                </ng-container>
            </ng-container>

            <!--Reset Password-->
            <ng-container *ngIf="isResetPassword">
                <div class="forgotPasswordHeader">
                    <span>Reset Password</span>
                    <!-- <mat-icon style="margin-left: auto; font-weight: 600;" (click)="closeResetPassword()"
                        class="cursorPointer customMatIcon">close</mat-icon> -->
                </div>
                <mat-form-field class="loginCredentialFields loginEmailId zoomLevelMatFormfield" appearance="outline">
                    <input type="email" matInput [(ngModel)]="resetPassword.emailId" placeholder="Email Id"
                        [disabled]="resetPassword.emailId">
                </mat-form-field>

                <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                    <input [type]="hideNewPassword ? 'password' : 'text'" matInput
                        [(ngModel)]="resetPassword.newPassword" placeholder="New password">
                    <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideNewPassword">
                        {{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>

                <mat-form-field class="loginCredentialFields loginPassword zoomLevelMatFormfield" appearance="outline">
                    <input [type]="hideConfirmPassword ? 'password' : 'text'" matInput
                        [(ngModel)]="resetPassword.confirmedPassword" placeholder="Confirm password">
                    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                        {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </ng-container>
        </div>

        <div class="loginAction" [class.inlineBtrnGrp]="data.isAccessTokenExpired ||  isChangePassword"
            [ngClass]="(isChangePassword) ? 'changePasswordInputs' : ''">
            <ng-container *ngIf="!isForgetPasswordEnabled && !isResetPassword">
                <button *ngIf="data.isAccessTokenExpired" class="btnLoginCancel" mat-flat-button color="default"
                    (click)="CancelLogin()">Cancel</button>
                <button *ngIf="isChangePassword" class="btnLoginCancel" mat-flat-button color="default"
                    (click)="CancelChangePassword()">Cancel</button>
                <button class="btnLogin" mat-flat-button color="primary" *ngIf="!isChangePassword"
                    (click)="validateLogin()">Login</button>
                <button class="btnLogin" mat-flat-button color="primary" *ngIf="isChangePassword"
                    [disabled]="((!forgetPasswordObj.newPassword || !forgetPasswordObj.confirmedPassword) || (forgetPasswordObj.newPassword != forgetPasswordObj.confirmedPassword) || (forgetPasswordObj.newPassword == forgetPasswordObj.oldPassword))"
                    (click)="setNewPassword()">{{data.confirmButtonText}}</button>
            </ng-container>

            <ng-container *ngIf="isForgetPasswordEnabled">
                <ng-container *ngIf="!isCodeVerified">
                    <ng-container *ngIf="!isMailsend && !showLoader">
                        <button class="btnLogin" mat-flat-button color="primary" (click)="sendMail()"
                            [disabled]="!validEmail">Send Verification Code</button>
                    </ng-container>
                    <ng-container *ngIf="!isMailsend && showLoader">
                        <button class="btnLogin" mat-flat-button color="primary" [disabled]="true">Sending mail
                            ...</button>
                    </ng-container>

                    <ng-container *ngIf="isMailsend ">
                        <button class="btnLogin" mat-flat-button color="primary" (click)="verifyCode()"
                            [disabled]="!forgetPasswordObj.verificationCode">Submit
                            Verification Code</button>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="isCodeVerified">
                    <button class="btnLogin" mat-flat-button color="primary"
                        (click)="setNewPassword()">Submit</button>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="isResetPassword">
                <button class="btnLogin" mat-flat-button color="primary" (click)="newUserPasswordReset()">Reset
                    Password</button>
            </ng-container>
        </div>

        <ng-container
            *ngIf="!data.isAccessTokenExpired && !isForgetPasswordEnabled && !isChangePassword && !isResetPassword && !appConfig?.newUserPasswordRequired">
            <div class="forgetPasswordText cursorPointer" (click)="enableForgetPassword()"> Forgot password ?</div>
        </ng-container>
        <ng-container *ngIf="isResetPassword">
            <div style="color: black; padding-top: 1vh;">
                <div>Make sure your password:</div>
                <ul>
                    <li>Is longer than 7 characters</li>
                    <li>Includes numbers, special characters, upper case & lowercase letter</li>
                </ul>
            </div>
        </ng-container>
        <!-- <ng-container
            *ngIf="(forgetPasswordObj.newPassword == forgetPasswordObj.oldPassword) && (forgetPasswordObj.newPassword && forgetPasswordObj.oldPassword)">
            <div style="color: black; padding-top: 1vh;">
                <div>Old password and new password should not match.</div>
            </div>
        </ng-container> -->
    </div>
</div>