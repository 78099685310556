<div class="confirmDialogDiv">
  <ng-container *ngIf="!displayOnly && !metadataAddition">
    <div class="modal-header dialog-header">
      <div class="headerTitle">
        <div class="dialog-title">{{showData.headerTitle}}</div>
      </div>
      <div class="headerIcon float-right" *ngIf="!waitForResponse">
        <!-- <i class="fa fa-times" (click)="closeDialog()"></i> -->
        <mat-icon class="cursorPointer iconClose" (click)="closeDialog()">close</mat-icon>
      </div>
    </div>
    <div class="container dialog-container modal-body">
      <div class="dispText">{{showData.confirmMsg}}</div>
    </div>
    <div class="actions dialog-actions modal-footer">
      <button mat-flat-button class="cancelBtn cursorPointer"
        (click)="closeDialog()">{{showData.cancelButtonText}}</button>
      <button mat-flat-button *ngIf="!waitForResponse" color="primary" class="btn-default confirmBtn cursorPointer"
        (click)="confirmSuccess()" cdkFocusInitial>{{showData.confirmButtonText}}</button>
      <button mat-flat-button *ngIf="waitForResponse" color="primary" class="btn-default confirmBtn cursorPointer"
        cdkFocusInitial>Saving</button>
    </div>
  </ng-container>

  <ng-container *ngIf="displayOnly ">
    <div class="modal-header dialog-header ">
      <div class="headerTitle">
        <div class="dialog-title">{{showData.headerTitle}}</div>
      </div>
      <div class="headerIcon float-right" *ngIf="!waitForResponse">
        <!-- <i class="fa fa-times" (click)="closeDialog()"></i> -->
        <mat-icon class="cursorPointer iconClose" (click)="closeDialog()">close</mat-icon>
      </div>
    </div>
    <div class="container dialog-container modal-body profileDetailsDisplayOnly">
      <div class="profileName">
        <span class="profileNameTitle">Profile : </span>
        <span class="profileNameValue">{{data.profileData[0].name}}</span>
      </div>
      <div class="profileDescription">
        <span class="profileDescTitle">Description : </span>
        <span class="profileDescValue">{{(data.profileData[0].description) ? data.profileData[0].description :
          '--'}}</span>
      </div>
      <div class="profileDetails">
        <div class="profileIndvDiv">
          <div class="profileIndTitle">Species</div>
          <div class="profileIndvValues" *ngFor="let item of data.profileData[0].metadataInfo['species.class']">
            {{item.code}}
          </div>

        </div>
        <div class="profileIndvDiv">
          <div class="profileIndTitle">Stain</div>
          <div class="profileIndvValues" *ngFor="let item of data.profileData[0].metadataInfo['stain.class']">
            {{item.code}}
          </div>
        </div>
        <div class="profileIndvDiv">
          <div class="profileIndTitle">Tissue Type</div>
          <div class="profileIndvValues" *ngFor="let item of data.profileData[0].metadataInfo['tissue_type.class']">
            {{item.code}}
          </div>
        </div>
        <div class="profileIndvDiv">
          <div class="profileIndTitle">Organ</div>
          <div class="profileIndvValues" *ngFor="let item of data.profileData[0].metadataInfo['tissue.class']">
            {{item.code}}
          </div>
        </div>
      </div>
      <div class="totalThresholdDiv">
        <div class="totalThresholdTitle">
          Total Artefact Area :
        </div>
        <div class="totalThresholdValue">
          &nbsp;&nbsp;{{(data.profileData[0].totalArtefactAreaPercentage) ?
          data.profileData[0].totalArtefactAreaPercentage + '%'
          : 'Not set'}}
        </div>
      </div>
      <div class="profileThresholdsDiv">
        <ng-container *ngIf="data.profileData[0].individualThresholdEnabled">
          <ng-container *ngFor="let item of data.profileData[0].individualArtefactPercentage | keyvalue">
            <div class="thresholdDiv">
              <div class="thresholdTitle">
                {{item.key}} :
              </div>
              <div class="thresholdValue">
                &nbsp;&nbsp;{{ item.value}}%
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!data.profileData[0].individualThresholdEnabled ">
          <div class="totalThresholdDiv">
            <div class="totalThresholdTitle">
              Individual Artefact Area :
            </div>
            <div class="totalThresholdValue">
              &nbsp;&nbsp; Not set
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="metadataAddition">
    <div class="modal-header dialog-header ">
      <div class="headerTitle">
        <div class="dialog-title">{{showData.headerTitle}}</div>
      </div>
      <div class="headerIcon float-right" *ngIf="!waitForResponse">
        <!-- <i class="fa fa-times" (click)="closeDialog()"></i> -->
        <mat-icon class="cursorPointer iconClose" (click)="closeDialog()">close</mat-icon>
      </div>
    </div>
    <div class="container dialog-container modal-body metadataAdditionDiv">
      <ng-container *ngFor="let item of data.metadataArr; let i = index">
        <mat-form-field appearance="outline" class="commonInputHeight zoomLevelMatFormfield metadataFieldsSelect">
          <mat-label>{{item.name}}</mat-label>
          <mat-select multiple (selectionChange)="metadataSelection($event, item.name, i)">
            <mat-option *ngFor="let ref of item.refdataList" [value]="ref.code">{{ref.code}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="actions dialog-actions modal-footer">
      <button mat-flat-button class="cancelBtn cursorPointer"
        (click)="closeDialog()">{{showData.cancelButtonText}}</button>
      <button mat-flat-button *ngIf="!waitForResponse" color="primary" class="btn-default confirmBtn cursorPointer"
        (click)="confirmSuccess()" cdkFocusInitial>{{showData.confirmButtonText}}</button>
    </div>
  </ng-container>
</div>