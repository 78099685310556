import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigureService {
  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  getAllFolderLocations() {
    return this.http.get(`${this.config.apiUrl}slide/scanner`);
  }

  addFolderLocation(data: any) {
    return this.http.post(`${this.config.apiUrl}slide/scanner/v2`, data);
  }

  getConfigData() {
    return this.http.get(`${this.config.apiUrl}slide/qcConfig`);
  }

  postConfigdata(data: any) {
    return this.http.post(`${this.config.apiUrl}slide/qcConfig`, data);
  }

  changeSlideResults() {
    return this.http.get(`${this.config.apiUrl}slide/change/issueStatus`)
  }

  deleteLocation(id: any) {
    return this.http.delete(`${this.config.apiUrl}slide/scanner/${id}`);
  }

  editSourceData(data: any) {
    return this.http.put(`${this.config.apiUrl}slide/scanner`, data);
  }

  getProfilesList() {
    return this.http.get(`${this.config.apiUrl}slide/profile`);
  }

  getDataConfigData() {
    return this.http.get(`${this.config.apiUrl}slide/dataConfigField?withRefData=true`);
  }

  addMetadataToSlides(payLoad: any) {
    return this.http.post(`${this.config.apiUrl}slide/metadata/bulk`, payLoad);
  }

  addDataConfigurationDetails(dataConfigObj: any) {
    return this.http.post(`${this.config.apiUrl}slide/data/configuration/refData`, dataConfigObj);
  }

  deleteDataConfigurationDetails(dataConfigId: any) {
    return this.http.delete(`${this.config.apiUrl}slide/data/configuration/refData/${dataConfigId}`);
  }

  addNewProfile(profileForm: any) {
    return this.http.post(`${this.config.apiUrl}slide/profile`, profileForm);
  }

  editProfile(profileForm: any) {
    return this.http.put(`${this.config.apiUrl}slide/profile`, profileForm);
  }

  deleteProfile(profileId: any) {
    return this.http.delete(`${this.config.apiUrl}slide/profile/${profileId}`);
  }

}
